.TabMenu {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 1rem;
}

.TabMenuHighlight {
	align-items: baseline;
	gap: 0.5rem;
}

.TabMenuUnderline {
	border-bottom: 1px solid var(--base-color-lightest);
	gap: 2rem;
}

.TabMenuAccentUnderline {
	border-bottom: 1px solid var(--base-color-lightest);
	gap: 2rem;
}

.TabMenuLinkHighlight {
	padding: var(--padding-half) var(--container-padding-xs);
}

.FullScreenTabMenu {
	width: 100%;
}

.TabMenu > * {
	padding-bottom: var(--spacing-xs);
}

.ActiveTabHighlight {
	background: var(--tab-menu-hightlight-color-background);
	border-radius: var(--border-radius-half);
}

.ActiveTabUnderline {
	border-bottom: 3px solid var(--tab-menu-underline-color-border);
}

.ActiveTabAccentUnderline {
	border-bottom: 3px solid var(--tab-menu-underline-color-border);
}
