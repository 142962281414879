.SearchContainer {
	border-radius: 0;
	padding: 0.875rem 1.125rem;
}

.Search {
	flex-grow: 1;
}

.SearchInput {
	border-radius: 0;
	line-height: 1rem;
	border: none;
	padding: 0;
}

.SearchInput:hover {
	border: none;
}

.SearchInput:focus {
	border: none;
}
