.Label {
	padding: 0.2rem;
	border-radius: var(--border-radius-sm);
	font-size: 0.75rem;
	white-space: nowrap;
}

.LabelColorRed {
	color: var(--color-red);
	background-color: rgb(217 54 54 / 10%);
}

.LabelColorYellow {
	color: var(--color-yellow);
	background-color: rgb(242 201 76 / 10%);
}

.LabelColorOrange {
	color: var(--color-orange);
	background-color: rgb(232 148 12 / 10%);
}

.LabelColorGreen {
	color: var(--color-green-dark);
	background-color: rgb(30 133 76 / 10%);
}

.LabelColorGrey {
	color: var(--base-color-medium);
	background-color: rgb(135 133 133 / 10%);
}

.LabelColorBlue {
	color: var(--color-blue);
	background-color: rgb(46 24 216 / 10%);
}

.LabelColorPurple {
	color: var(--color-purple-6);
	background-color: var(--color-purple-1);
}

.LabelColorWhite {
	color: var(--color-black);
	background-color: var(--color-white);
}
